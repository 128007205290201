<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newEmployee' ||modalId=='editEmployee' || modalId=='editProfile'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">{{(modalId=='editEmployee' || modalId=='editProfile')?'Edit Employee':'Add New Employee'}}</h5>
            <a class="close" @click="$store.dispatch('modalClose', 'newEmployee')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mt-0 mb-1">
                            <input
                              type="file"
                              id="Employee_image"
                              style="display: none"
                              @change="base64Image"
                            />
                            <label for="Employee_image" class="popup-img-container">
                              <span>
                                Browse
                                <br />Image
                              </span>
                              <div class="popup_img">
                                <img
                                  class="popup_img"
                                  :src="
                                    formData.img
                                      ? formData.img
                                      : '/assets/img/user_icon.png'
                                  "
                                  alt
                                />
                                <div id="change_img">
                                  Change
                                  <br />Image
                                </div>
                              </div>
                            </label>
                          </div>
                          <div class="col-md-7 pl-0 pd-t-30 pb-0">
                            <div class="row">
                              <div class="col-md-6 mr-0 pr-0">Employee ID *</div>
                              <div class="col-md-6 ml-0 pl-0">
                                <input
                                  id="emp-id"
                                  class="form-control"
                                  placeholder="id"
                                  v-model="formData.employee_id"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Employee Name *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              id="emp_name"
                              class="form-control"
                              placeholder="Employee Name"
                              v-model="formData.name"
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Current Address</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              id="current_address"
                              class="form-control"
                              placeholder="Address"
                              v-model="formData.current_address"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Permanent Address</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Address"
                              v-model="formData.permanent_address"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Date of Birth</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              class="form-control"
                              placeholder="DOB"
                              v-model="formData.dob"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Sex</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select name="gender" class="form-control" v-model="formData.sex">
                              <option value disabled>Select Sex</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Blood Group</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select name id class="form-control" v-model="formData.blood_group">
                              <option value disabled>Select Blood Group</option>
                              <option value="A+">A+</option>
                              <option value="A-">A-</option>
                              <option value="B+">B+</option>
                              <option value="B-">B-</option>
                              <option value="O+">O+</option>
                              <option value="O-">O-</option>
                              <option value="AB+">AB+</option>
                              <option value="AB-">AB-</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Citizenship No</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              
                              class="form-control"
                              placeholder="Citizenship No"
                              v-model="formData.citizenship_no"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">PAN No</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              
                              class="form-control"
                              placeholder="PAN No."
                              v-model="formData.pan_no"
                              @keyup="checkPanValidation"
                            />
                            <span v-if="pan_error_msg" class="tx-danger">Pan number must be 9 digits</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Contact Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Phone No</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="tel"
                              placeholder="Phone No."
                              name="bank"
                        
                              class="form-control"
                              v-model="formData.phone_no"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Mobile No</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="tel"
                              placeholder="Mobile No."
                              name="bank"
                              
                              class="form-control"
                              v-model="formData.mobile_no"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Email</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="email"
                              name="bank"
                              placeholder="abc@domain.com"
                              class="form-control"
                              v-model="formData.email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Parent Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Father Name</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              name="expense_amount"
                              class="form-control"
                              placeholder="Name"
                              v-model="formData.father_name"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Father Mobile</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="tel"
                              name
                              class="form-control"
                              placeholder="98XXXXXXXX"
                              v-model="formData.father_mobile_no"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Mother Name</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              name
                              class="form-control"
                              placeholder="Name"
                              v-model="formData.mother_name"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Mother Mobile</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="tel"
                              name
                              class="form-control"
                              placeholder="98XXXXXXXX"
                              v-model="formData.mother_mobile_no"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Official Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Designation</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              name
                              class="form-control"
                              placeholder="Manager"
                              v-model="formData.designation"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Joining Date *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              name
                              class="form-control"
                              v-model="formData.joining_date"
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Joining Branch *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              name="branch"
                              id="branch"
                              class="form-control"
                              v-model="formData.branch_id"
                              v-if="modalId == 'editEmployee'"
                              required
                            >
                              <option value="" disabled>Select Branch</option>
                                <option
                                v-for="(branch, index) in dataLists1.filter_branchs"
                                :value="branch.id"
                                :key="index"
                              >{{ branch.name }}</option>
                              
                            </select>
                             <select
                              name="branch"
                              id="branch"
                              class="form-control"
                              v-model="formData.branch_id"
                              :disabled="branch_disabled"
                              v-else
                              required
                            >
                              <option value="" disabled>Select Branch</option>
                                <option
                                v-for="(branch, index) in dataLists1.branchs"
                                :value="branch.id"
                                :key="index"
                              >{{ branch.name }}</option>
                              
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Salary *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              name
                              min="1"
                              class="form-control"
                              placeholder="NPR 0.00"
                              v-model="formData.salary"
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2" v-if="formData.salary_master_count != 0">
                          <div class="col-md-5 mr-0 pr-0">Opening Balance</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <span class="tx-danger">Cannot Update.Employee Balance used already.</span>
                          </div>
                        </div>
                         <div class="row mb-2" v-else>
                          <div class="col-md-5 mr-0 pr-0">Opening Balance</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              min="0"
                              name="opening_balance"
                              class="form-control"
                              placeholder="NPR 0.00"
                              v-model="formData.opening_due"
                            />
                          </div>
                        </div>
                        <div class="row mb-2" v-if=" formData.salary_master_count != 0">
                          <div class="col-md-5 mr-0 pr-0">Advance Amount</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <span class="tx-danger">Cannot Update.Employee Advance used already.</span>
                          </div>
                        </div>
                         <div class="row mb-2" v-else>
                          <div class="col-md-5 mr-0 pr-0">Advance Amount</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              min="0"
                              name="opening_balance"
                              class="form-control"
                              placeholder="NPR 0.00"
                              v-model="formData.advance"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Document</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <div class="input-group zi-0">
                              <input
                                type="file"
                                name="document"
                                id="file"
                                style="display: none"
                                @change="base64Document"
                              />
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Upload PDF / Image"
                                readonly
                                style="background: #f4f7fa"
                              />
                              <label for="file" class="btn-primary cursor-p px-1 pt-1 font-s">Browse</label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Status *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <div class="input-group zi-0">
                              <select name="status" class="form-control" id="emp_status" v-model="formData.status">
                                <option value="1">Active</option>
                                <option value="0">In-Active</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="group" id="description">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              type="text"
                              name="description"
                              id="description"
                              style="height: 3.4rem"
                              v-model="formData.description"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary"
                  :disabled="disableSubmitButton"
                ><span v-if="pan_error_msg==false">{{ disableSubmitButton ? 'Submitting....' : 'Submit'}}</span><span v-else>Submit</span></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "../Services/Services";
export default {
  data() {
    return {
      loading: false,
      disableSubmitButton: false,
      formData: {
        id: "",
        employee_id: "",
        img: "",
        name: "",
        current_address: "",
        permanent_address: "",
        citizenship_no: "",
        sex: "",
        dob: "",
        blood_group: "",
        document:"",
        pan_no: "",
        phone_no: "",
        mobile_no: "",
        status:1,
        email: "",
        designation: "",
        joining_date: "",
        salary: "",
        opening_due: 0,
        closing_due: 0,
        advance: 0,
        father_name: "",
        father_mobile_no: "",
        mother_name: "",
        mother_mobile_no: "",
        branch_id: "",
        description: "",
        salary_master_count:"",
      },
      branch_disabled:false,
      pan_error_msg:false,
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists1",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
    ...mapGetters("employees", ["employeeLists","employeeProfile"]),
  },
  mounted() {
    this.setDate();
  },
  methods: {
    base64Image(e) {
      this.formData.img = null;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.formData.img = e.target.result;
      };
    },
    base64Document(e) {
      this.formData.document = null;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.formData.document = e.target.result;
      };
    },
    checkPanValidation(){
        if(!isNaN(this.formData.pan_no) && this.formData.pan_no != '' && this.formData.pan_no != null){
          if(this.formData.pan_no.length != 9 || this.formData.pan_no < 0){
              this.disableSubmitButton = true;
              this.pan_error_msg = true;
          }else{
            this.disableSubmitButton = false;
            this.pan_error_msg = false;
          }
        }else{
          this.disableSubmitButton = false;
          this.pan_error_msg = false;
        }
    },
    submit() {
      if (this.modalMode == "create") {
        this.disableSubmitButton = true;
        Services.createNewemployee(this.formData)
          .then((res) => {
            this.$emit("parent-event");
            this.$store.dispatch("modalClose");
            this.setNotification(res.data.success_message);
          })
          .catch((err) => {
            this.error = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              let error = err.response.data.error_message;
              this.setNotification(error);
            }
          })
          .finally(() => {
            this.loading = false;
            this.disableSubmitButton = false;
          });
      } else if (this.modalMode == "edit") {
        this.disableSubmitButton = true;
        Services.editEmployee(this.formData, this.formData.id)
          .then((res) => {
            if(this.modalId == 'editProfile'){
              this.$store.commit("employees/setEmployeeProfile", this.formData);
              this.$emit("parent-event");
            }else{
              this.$emit("parent-event");
            }
              this.$store.dispatch("modalClose");
              this.setNotification(res.data.success_message);
          })
          .catch((err) => {
            this.error = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              let error = err.response.data.error_message;
              this.setNotification(error);
            }
          })
          .finally(() => {
            this.loading = false;
            this.disableSubmitButton = false;
          });
      }
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    closingDue() {
      this.formData.closing_due = this.formData.opening_due;
    },
    setDate() {
      this.formData.joining_date = this.today;
    },
    clearData() {
      this.formData.employee_id = "";
      this.formData.img = "";
      this.formData.document="";
      this.formData.name = "";
      this.formData.current_address = "";
      this.formData.permanent_address = "";
      this.formData.citizenship_no = "";
      this.formData.sex = "";
      this.formData.dob = "";
      this.formData.blood_group = "";
      this.formData.pan_no = "";
      this.formData.phone_no = "";
      this.formData.mobile_no = "";
      this.formData.email = "";
      this.formData.designation = "";
      this.formData.joining_date = "";
      this.formData.salary = "";
      this.formData.opening_due = 0;
      this.formData.closing_due = 0;
      this.formData.advance = "";
      this.formData.father_name = "";
      this.formData.father_mobile_no = "";
      this.formData.mother_name = "";
      this.formData.mother_mobile_no = "";
      this.formData.branch_id = "";
      this.formData.description = "";
      this.formData.user_id = "";
      this.formData.status=1;
      this.branch_disabled=false;
    },
  },
  watch: {
    modalId(value) {
      if (value == "editEmployee") {
        this.clearData();
        this.$store.commit("getData1", `api/employee/create`);
        let id = this.dataId;
        let details = this.employeeLists.find(function (data) {
          return data.id == id;
        });
        this.formData = { ...details };
      }
      if(value == 'editProfile'){
        this.clearData();
        this.$store.commit("getData1", `api/employee/create`);
        let details = this.employeeProfile
        this.formData = {...details};
      }
    },
    "formData.opening_due"() {
      this.closingDue();
    },
    modalMode(value) {
      if (value == "create") {
        this.clearData();
        Services.getBranchAttributes().then(res=>{
          this.$store.commit("setDataLists1",res.data.data);
          if(res.data.data.filter_branchs.length == 1){
            this.formData.branch_id = res.data.data.filter_branchs[0].id;
            this.branch_disabled=true;
          }
        }).catch(err=>{
          console.log(err);
        });
      }
    },
  },
};
</script>
